import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://d24802053f2cb8694c559fb9def3f883@o1388406.ingest.sentry.io/4506662914031616",
  debug: false,
  environment: "dev",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});